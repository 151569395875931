import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from './ui/Card';
import { Button } from './ui/Button';

const HomeContent = () => {
  // Placeholder data for tracker results
  const trackerResults = {
    waterIntake: 1.5, // liters
    steps: 8000,
    caloriesIntake: 1800,
    hobbiesTime: 2, // hours
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-[#323F4B]">Welcome to EasyGP</h1>
      
      <Card>
        <div className="p-4">
          <h2 className="text-xl font-semibold">Today's Summary</h2>
        </div>
        <div className="p-4 grid grid-cols-2 gap-4">
          <div>
            <p className="font-semibold">Water Intake</p>
            <p>{trackerResults.waterIntake}L / 2L</p>
          </div>
          <div>
            <p className="font-semibold">Steps</p>
            <p>{trackerResults.steps} / 10,000</p>
          </div>
          <div>
            <p className="font-semibold">Calories Intake</p>
            <p>{trackerResults.caloriesIntake} / 2000 kcal</p>
          </div>
          <div>
            <p className="font-semibold">Hobbies Time</p>
            <p>{trackerResults.hobbiesTime}h / 3h</p>
          </div>
        </div>
      </Card>

      <Card>
        <div className="p-4">
          <h2 className="text-xl font-semibold">Quick Links</h2>
        </div>
        <div className="p-4 grid grid-cols-2 gap-4">
          <Link to="/profile">
            <Button variant="outline" className="w-full">Profile</Button>
          </Link>
          <Link to="/stats">
            <Button variant="outline" className="w-full">Activity Tracker</Button>
          </Link>
          <Link to="/profile/conditions">
            <Button variant="outline" className="w-full">Conditions</Button>
          </Link>
          <Link to="/profile/medications">
            <Button variant="outline" className="w-full">Medications</Button>
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default HomeContent;
