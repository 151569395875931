import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card } from './ui/Card';

const Header = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Placeholder for key bio data
  const keyBioData = {
    age: 30,
    bmi: 22.5,
    bloodPressure: '120/80',
  };

  return (
    <header className="bg-[#3AE7E1]/30 backdrop-blur-md backdrop-saturate-150 shadow-lg p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
            <circle cx="50" cy="50" r="45" stroke="#3AE7E1" strokeWidth="10"/>
            <circle cx="50" cy="50" r="30" fill="#FF9466"/>
            <path d="M50 30V70M30 50H70" stroke="white" strokeWidth="10" strokeLinecap="round"/>
            <circle cx="85" cy="15" r="8" fill="#323F4B"/>
          </svg>
          <span className="text-xl font-bold">
            <span className="text-[#323F4B]">Easy</span>
            <span className="text-[#FF9466]">GP</span>
          </span>
        </div>
        <Card className="p-2 flex space-x-4">
          <span>Age: {keyBioData.age}</span>
          <span>BMI: {keyBioData.bmi}</span>
          <span>BP: {keyBioData.bloodPressure}</span>
        </Card>
      </div>
      <nav className="mt-2">
        <ol className="list-none p-0 inline-flex">
          <li className="flex items-center">
            <Link to="/" className="text-[#323F4B] hover:text-[#FF9466]">Home</Link>
          </li>
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;
            return (
              <li key={name} className="flex items-center">
                <span className="mx-2">/</span>
                {isLast ? (
                  <span className="text-[#FF9466]">{name}</span>
                ) : (
                  <Link to={routeTo} className="text-[#323F4B] hover:text-[#FF9466]">
                    {name}
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </header>
  );
};

export default Header;
