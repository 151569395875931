import React from 'react';

export const Slider = ({ value, onValueChange, min = 0, max = 100, step = 1, ...props }) => {
  const handleChange = (event) => {
    const newValue = Number(event.target.value);
    onValueChange([newValue]);
  };

  return (
    <input
      type="range"
      value={value[0]}
      onChange={handleChange}
      min={min}
      max={max}
      step={step}
      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
      {...props}
    />
  );
};
