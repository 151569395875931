import React, { useState } from 'react';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';

const Conditions = () => {
  const [conditions, setConditions] = useState([
    { id: 1, name: 'Hypertension' },
    { id: 2, name: 'Type 2 Diabetes' },
  ]);
  const [newCondition, setNewCondition] = useState('');

  const addCondition = () => {
    if (newCondition.trim() !== '') {
      setConditions([...conditions, { id: Date.now(), name: newCondition.trim() }]);
      setNewCondition('');
    }
  };

  const removeCondition = (id) => {
    setConditions(conditions.filter(condition => condition.id !== id));
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Current Conditions</h3>
      {conditions.map((condition) => (
        <Card key={condition.id}>
          <div className="flex justify-between items-center p-4">
            <span>{condition.name}</span>
            <Button variant="destructive" size="sm" onClick={() => removeCondition(condition.id)}>
              Remove
            </Button>
          </div>
        </Card>
      ))}
      <div className="flex space-x-2">
        <input
          type="text"
          value={newCondition}
          onChange={(e) => setNewCondition(e.target.value)}
          placeholder="Enter new condition"
          className="flex-grow p-2 border rounded"
        />
        <Button onClick={addCondition}>Add Condition</Button>
      </div>
    </div>
  );
};

export default Conditions;
