import React, { useState } from 'react';
import { Button } from '../ui/Button';
import { Slider } from '../ui/Slider';

const ageGroups = [
  { icon: 'M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z', label: 'Baby', years: '0-3' },
  { icon: 'M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z', label: 'Child', years: '4-12' },
  { icon: 'M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z', label: 'Teen', years: '13-19' },
  { icon: 'M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z', label: 'Young Adult', years: '20-39' },
  { icon: 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z', label: 'Adult', years: '40-59' },
  { icon: 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z', label: 'Senior', years: '60+' },
];

const BasicInfo = () => {
  const [gender, setGender] = useState('male');
  const [age, setAge] = useState(30);
  const [height, setHeight] = useState(170);
  const [weight, setWeight] = useState(70);
  const [systolic, setSystolic] = useState(120);
  const [diastolic, setDiastolic] = useState(80);

  const calculateBMI = () => {
    const bmi = weight / ((height / 100) ** 2);
    return bmi.toFixed(1);
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-semibold mb-2">Sex and Age</h3>
        <div className="flex space-x-4 mb-4">
          <Button
            variant={gender === 'male' ? 'default' : 'outline'}
            onClick={() => setGender('male')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-4 w-4 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
            Male
          </Button>
          <Button
            variant={gender === 'female' ? 'default' : 'outline'}
            onClick={() => setGender('female')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-4 w-4 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
            Female
          </Button>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
          {ageGroups.map((group) => (
            <Button
              key={group.label}
              variant="outline"
              className="flex flex-col items-center p-2"
              onClick={() => setAge(parseInt(group.years.split('-')[0]))}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-8 w-8 mb-1">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={group.icon} />
              </svg>
              <span className="text-xs">{group.label}</span>
              <span className="text-xs text-muted-foreground">{group.years} years</span>
            </Button>
          ))}
        </div>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">Blood Pressure</h3>
        <div className="flex space-x-4">
          <div className="flex-1">
            <label className="text-sm font-medium">Systolic</label>
            <Slider
              value={[systolic]}
              onValueChange={(value) => setSystolic(value[0])}
              max={200}
              step={1}
            />
            <span className="text-sm">{systolic} mmHg</span>
          </div>
          <div className="flex-1">
            <label className="text-sm font-medium">Diastolic</label>
            <Slider
              value={[diastolic]}
              onValueChange={(value) => setDiastolic(value[0])}
              max={120}
              step={1}
            />
            <span className="text-sm">{diastolic} mmHg</span>
          </div>
        </div>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">BMI Calculator</h3>
        <div className="space-y-4">
          <div>
            <label className="text-sm font-medium">Height (cm)</label>
            <Slider
              value={[height]}
              onValueChange={(value) => setHeight(value[0])}
              min={100}
              max={220}
              step={1}
            />
            <span className="text-sm">{height} cm</span>
          </div>
          <div>
            <label className="text-sm font-medium">Weight (kg)</label>
            <Slider
              value={[weight]}
              onValueChange={(value) => setWeight(value[0])}
              min={30}
              max={200}
              step={1}
            />
            <span className="text-sm">{weight} kg</span>
          </div>
          <div className="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
            </svg>
            <span className="font-semibold">BMI: {calculateBMI()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
