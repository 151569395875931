import React, { useState } from 'react';
import { Card } from './ui/Card';
import { Button } from './ui/Button';

const ChatBot = () => {
  const [messages, setMessages] = useState([
    { text: "Hello! I'm your health assistant. How can I help you today?", sender: 'bot' }
  ]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim() === '') return;

    setMessages([...messages, { text: input, sender: 'user' }]);
    setInput('');

    // Simulate bot response
    setTimeout(() => {
      let botResponse = "I'm sorry, I don't have enough information to answer that question.";

      if (input.toLowerCase().includes('water')) {
        botResponse = "Your daily water intake target is 2000ml. Make sure to stay hydrated!";
      } else if (input.toLowerCase().includes('step')) {
        botResponse = "Your daily step target is 10,000 steps. Keep moving!";
      } else if (input.toLowerCase().includes('calorie')) {
        botResponse = "Your daily calorie intake target is 2000 calories. Remember to maintain a balanced diet!";
      } else if (input.toLowerCase().includes('hobby') || input.toLowerCase().includes('leisure')) {
        botResponse = "Your daily hobby/leisure time target is 2 hours. It's important to take time for yourself!";
      }

      setMessages(prev => [...prev, { text: botResponse, sender: 'bot' }]);
    }, 1000);
  };

  return (
    <Card className="h-[calc(100vh-8rem)]">
      <div className="p-4">
        <h2 className="text-2xl font-bold">Health Assistant</h2>
      </div>
      <div className="flex flex-col h-full p-4">
        <div className="flex-grow overflow-y-auto mb-4">
          {messages.map((message, index) => (
            <div key={index} className={`mb-2 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
              <span className={`inline-block p-2 rounded-lg ${message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}>
                {message.text}
              </span>
            </div>
          ))}
        </div>
        <div className="flex">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            placeholder="Type your message here..."
            className="flex-grow p-2 border rounded-l-lg"
          />
          <Button onClick={handleSend} className="rounded-l-none">Send</Button>
        </div>
      </div>
    </Card>
  );
};

export default ChatBot;
