import React, { useState } from 'react';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';

const Medications = () => {
  const [medications, setMedications] = useState([
    {
      id: 1,
      name: 'Lisinopril',
      dosage: '10mg',
      frequency: 'Once daily',
      time: '08:00',
      startDate: '2023-01-01',
      endDate: '',
      ongoing: true,
      condition: 'Hypertension'
    }
  ]);
  const [isAdding, setIsAdding] = useState(false);
  const [newMedication, setNewMedication] = useState({
    name: '',
    dosage: '',
    frequency: '',
    time: '',
    startDate: '',
    endDate: '',
    ongoing: false,
    condition: ''
  });

  const addMedication = () => {
    if (newMedication.name.trim() !== '') {
      setMedications([...medications, { id: Date.now(), ...newMedication }]);
      setNewMedication({
        name: '',
        dosage: '',
        frequency: '',
        time: '',
        startDate: '',
        endDate: '',
        ongoing: false,
        condition: ''
      });
      setIsAdding(false);
    }
  };

  const removeMedication = (id) => {
    setMedications(medications.filter(medication => medication.id !== id));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewMedication({
      ...newMedication,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Current Medications</h3>
      {medications.map((medication) => (
        <Card key={medication.id}>
          <div className="p-4">
            <div className="flex justify-between items-start">
              <div>
                <h4 className="font-semibold">{medication.name}</h4>
                <p>Dosage: {medication.dosage}</p>
                <p>Frequency: {medication.frequency}</p>
                {medication.frequency === 'Once daily' && <p>Time: {medication.time}</p>}
                <p>Start Date: {medication.startDate}</p>
                <p>{medication.ongoing ? 'Ongoing' : `End Date: ${medication.endDate}`}</p>
                <p>Condition: {medication.condition}</p>
              </div>
              <Button variant="destructive" size="sm" onClick={() => removeMedication(medication.id)}>
                Remove
              </Button>
            </div>
          </div>
        </Card>
      ))}
      {isAdding ? (
        <Card>
          <div className="p-4">
            <input
              type="text"
              name="name"
              value={newMedication.name}
              onChange={handleInputChange}
              placeholder="Medication name"
              className="w-full p-2 mb-2 border rounded"
            />
            <input
              type="text"
              name="dosage"
              value={newMedication.dosage}
              onChange={handleInputChange}
              placeholder="Dosage"
              className="w-full p-2 mb-2 border rounded"
            />
            <select
              name="frequency"
              value={newMedication.frequency}
              onChange={handleInputChange}
              className="w-full p-2 mb-2 border rounded"
            >
              <option value="">Select frequency</option>
              <option value="Once daily">Once daily</option>
              <option value="Twice daily">Twice daily</option>
              <option value="As needed">As needed</option>
            </select>
            {newMedication.frequency === 'Once daily' && (
              <input
                type="time"
                name="time"
                value={newMedication.time}
                onChange={handleInputChange}
                className="w-full p-2 mb-2 border rounded"
              />
            )}
            <input
              type="date"
              name="startDate"
              value={newMedication.startDate}
              onChange={handleInputChange}
              className="w-full p-2 mb-2 border rounded"
            />
            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                name="ongoing"
                checked={newMedication.ongoing}
                onChange={handleInputChange}
                className="mr-2"
              />
              <label>Ongoing</label>
            </div>
            {!newMedication.ongoing && (
              <input
                type="date"
                name="endDate"
                value={newMedication.endDate}
                onChange={handleInputChange}
                className="w-full p-2 mb-2 border rounded"
              />
            )}
            <input
              type="text"
              name="condition"
              value={newMedication.condition}
              onChange={handleInputChange}
              placeholder="Associated condition"
              className="w-full p-2 mb-2 border rounded"
            />
            <div className="flex justify-end space-x-2">
              <Button variant="outline" onClick={() => setIsAdding(false)}>Cancel</Button>
              <Button onClick={addMedication}>Add Medication</Button>
            </div>
          </div>
        </Card>
      ) : (
        <Button onClick={() => setIsAdding(true)}>Add New Medication</Button>
      )}
    </div>
  );
};

export default Medications;
