import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from './ui/Card';
import { Tabs } from './ui/Tabs';
import BasicInfo from './profile/BasicInfo';
import Conditions from './profile/Conditions';
import Medications from './profile/Medications';
import Targets from './profile/Targets';

const ProfileContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(() => {
    const path = location.pathname.split('/').pop();
    return ['conditions', 'medications', 'targets'].includes(path) ? path : 'basic-info';
  });

  const handleTabChange = (value) => {
    setActiveTab(value);
    navigate(`/profile/${value}`);
  };

  return (
    <Card>
      <div className="p-4">
        <h2 className="text-2xl font-bold">Profile Information</h2>
      </div>
      <div className="p-4">
        <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
          <div className="flex space-x-2 mb-4">
            <button onClick={() => handleTabChange('basic-info')} className={`px-4 py-2 rounded ${activeTab === 'basic-info' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>Basic Info</button>
            <button onClick={() => handleTabChange('conditions')} className={`px-4 py-2 rounded ${activeTab === 'conditions' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>Conditions</button>
            <button onClick={() => handleTabChange('medications')} className={`px-4 py-2 rounded ${activeTab === 'medications' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>Medications</button>
            <button onClick={() => handleTabChange('targets')} className={`px-4 py-2 rounded ${activeTab === 'targets' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>Targets</button>
          </div>
          <div>
            {activeTab === 'basic-info' && <BasicInfo />}
            {activeTab === 'conditions' && <Conditions />}
            {activeTab === 'medications' && <Medications />}
            {activeTab === 'targets' && <Targets />}
          </div>
        </Tabs>
      </div>
    </Card>
  );
};

export default ProfileContent;
