import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from './ui/Button';

const Navigation = () => {
  const location = useLocation();

  const icons = [
    { name: 'home', icon: 'M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6', label: 'Home', path: '/' },
    { name: 'profile', icon: 'M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z', label: 'Profile', path: '/profile' },
    { name: 'stats', icon: 'M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z', label: 'Stats', path: '/stats' },
    { name: 'chat', icon: 'M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z', label: 'Chat', path: '/chat' },
  ];

  return (
    <nav className="bg-[#E1FCF8] p-4 shadow-lg">
      <ul className="flex justify-between mx-[15%]">
        {icons.map((item) => (
          <li key={item.name}>
            <Link to={item.path}>
              <Button
                variant="ghost"
                size="sm"
                className={`w-16 h-16 rounded-lg flex flex-col items-center justify-center ${
                  location.pathname === item.path
                    ? 'bg-[#3AE7E1] text-[#323F4B] shadow-md' 
                    : 'bg-white text-[#323F4B]'
                } hover:bg-[#FF9466]/20 hover:text-[#FF9466]`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 mb-1">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={item.icon} />
                </svg>
                <span className="text-xs">{item.label}</span>
              </Button>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
