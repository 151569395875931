import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Navigation from './components/Navigation';
import HomeContent from './components/HomeContent';
import ProfileContent from './components/ProfileContent';
import StatsContent from './components/StatsContent';
import ChatBot from './components/ChatBot';

function App() {
  return (
    <Router>
      <div className="flex flex-col h-screen bg-gradient-to-br from-pink-200 via-coral-300 to-teal-400">
        <Header />
        <main className="flex-grow overflow-y-auto p-4">
          <Routes>
            <Route path="/" element={<HomeContent />} />
            <Route path="/profile" element={<ProfileContent />} />
            <Route path="/stats" element={<StatsContent />} />
            <Route path="/chat" element={<ChatBot />} />
          </Routes>
        </main>
        <Navigation />
      </div>
    </Router>
  );
}

export default App;
