import React, { useState } from 'react';
import { Card } from './ui/Card';
import { Tabs } from './ui/Tabs';
import { Button } from './ui/Button';
import { Slider } from './ui/Slider';

const StatsContent = () => {
  const [view, setView] = useState('day');
  const [dayProgress, setDayProgress] = useState({
    waterIntake: 1500,
    steps: 7500,
    calorieIntake: 1800,
    hobbyTime: 1.5
  });
  const [weekProgress, setWeekProgress] = useState({
    waterIntake: [1500, 2000, 1800, 2200, 1900, 2100, 1700],
    steps: [7500, 10000, 8000, 12000, 9000, 11000, 8500],
    calorieIntake: [1800, 2000, 1900, 2100, 1950, 2050, 1850],
    hobbyTime: [1.5, 2, 1, 3, 2.5, 2, 1.5]
  });

  const targets = {
    waterIntake: 2000,
    steps: 10000,
    calorieIntake: 2000,
    hobbyTime: 2
  };

  const handleDayProgressChange = (key, value) => {
    setDayProgress(prev => ({ ...prev, [key]: value[0] }));
  };

  const calculateWeekAverage = (key) => {
    const sum = weekProgress[key].reduce((a, b) => a + b, 0);
    return (sum / 7).toFixed(1);
  };

  const renderProgressBar = (current, target) => {
    const percentage = Math.min((current / target) * 100, 100);
    return (
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${percentage}%` }}></div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Activity Tracker</h2>
      <div>
        <div className="flex space-x-2 mb-4">
          <Button onClick={() => setView('day')} className={view === 'day' ? 'bg-blue-500 text-white' : 'bg-gray-200'}>Day View</Button>
          <Button onClick={() => setView('week')} className={view === 'week' ? 'bg-blue-500 text-white' : 'bg-gray-200'}>Week View</Button>
        </div>
        {view === 'day' && (
          <Card>
            <div className="p-4">
              <h2 className="text-xl font-semibold">Today's Progress</h2>
            </div>
            <div className="p-4 space-y-4">
              <div>
                <h3 className="font-semibold">Water Intake (ml)</h3>
                <Slider
                  value={[dayProgress.waterIntake]}
                  onValueChange={(value) => handleDayProgressChange('waterIntake', value)}
                  max={4000}
                  step={100}
                />
                <p>{dayProgress.waterIntake} / {targets.waterIntake} ml</p>
                {renderProgressBar(dayProgress.waterIntake, targets.waterIntake)}
              </div>
              <div>
                <h3 className="font-semibold">Steps</h3>
                <Slider
                  value={[dayProgress.steps]}
                  onValueChange={(value) => handleDayProgressChange('steps', value)}
                  max={20000}
                  step={500}
                />
                <p>{dayProgress.steps} / {targets.steps} steps</p>
                {renderProgressBar(dayProgress.steps, targets.steps)}
              </div>
              <div>
                <h3 className="font-semibold">Calorie Intake</h3>
                <Slider
                  value={[dayProgress.calorieIntake]}
                  onValueChange={(value) => handleDayProgressChange('calorieIntake', value)}
                  max={4000}
                  step={50}
                />
                <p>{dayProgress.calorieIntake} / {targets.calorieIntake} calories</p>
                {renderProgressBar(dayProgress.calorieIntake, targets.calorieIntake)}
              </div>
              <div>
                <h3 className="font-semibold">Hobby/Leisure Time (hours)</h3>
                <Slider
                  value={[dayProgress.hobbyTime]}
                  onValueChange={(value) => handleDayProgressChange('hobbyTime', value)}
                  max={8}
                  step={0.5}
                />
                <p>{dayProgress.hobbyTime} / {targets.hobbyTime} hours</p>
                {renderProgressBar(dayProgress.hobbyTime, targets.hobbyTime)}
              </div>
            </div>
          </Card>
        )}
        {view === 'week' && (
          <Card>
            <div className="p-4">
              <h2 className="text-xl font-semibold">This Week's Progress</h2>
            </div>
            <div className="p-4 space-y-4">
              <div>
                <h3 className="font-semibold">Water Intake (ml)</h3>
                <p>Average: {calculateWeekAverage('waterIntake')} ml</p>
                {renderProgressBar(calculateWeekAverage('waterIntake'), targets.waterIntake)}
              </div>
              <div>
                <h3 className="font-semibold">Steps</h3>
                <p>Average: {calculateWeekAverage('steps')} steps</p>
                {renderProgressBar(calculateWeekAverage('steps'), targets.steps)}
              </div>
              <div>
                <h3 className="font-semibold">Calorie Intake</h3>
                <p>Average: {calculateWeekAverage('calorieIntake')} calories</p>
                {renderProgressBar(calculateWeekAverage('calorieIntake'), targets.calorieIntake)}
              </div>
              <div>
                <h3 className="font-semibold">Hobby/Leisure Time (hours)</h3>
                <p>Average: {calculateWeekAverage('hobbyTime')} hours</p>
                {renderProgressBar(calculateWeekAverage('hobbyTime'), targets.hobbyTime)}
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default StatsContent;
