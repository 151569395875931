import React, { useState, useEffect } from 'react';
import { Card } from '../ui/Card';
import { Slider } from '../ui/Slider';
import { Button } from '../ui/Button';

const Targets = () => {
  const [targets, setTargets] = useState({
    waterIntake: 2000, // ml
    steps: 10000,
    calorieIntake: 2000,
    hobbyTime: 2 // hours
  });

  const [suggestedTargets, setSuggestedTargets] = useState({
    waterIntake: 2000,
    steps: 10000,
    calorieIntake: 2000,
    hobbyTime: 2
  });

  // Simulating fetching suggested targets based on bio data
  useEffect(() => {
    // In a real application, this would be an API call or complex calculation
    setSuggestedTargets({
      waterIntake: 2500,
      steps: 12000,
      calorieIntake: 2200,
      hobbyTime: 3
    });
  }, []);

  const handleTargetChange = (key, value) => {
    setTargets(prev => ({ ...prev, [key]: value[0] }));
  };

  const applySuggestedTargets = () => {
    setTargets(suggestedTargets);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Your Health Targets</h3>
      <Button onClick={applySuggestedTargets}>Apply Suggested Targets</Button>
      
      <Card>
        <div className="p-4">
          <h4 className="font-semibold mb-2">Water Intake (ml)</h4>
          <Slider
            value={[targets.waterIntake]}
            onValueChange={(value) => handleTargetChange('waterIntake', value)}
            max={4000}
            step={100}
          />
          <p>Current target: {targets.waterIntake} ml (Suggested: {suggestedTargets.waterIntake} ml)</p>
        </div>
      </Card>

      <Card>
        <div className="p-4">
          <h4 className="font-semibold mb-2">Daily Steps</h4>
          <Slider
            value={[targets.steps]}
            onValueChange={(value) => handleTargetChange('steps', value)}
            max={20000}
            step={500}
          />
          <p>Current target: {targets.steps} steps (Suggested: {suggestedTargets.steps} steps)</p>
        </div>
      </Card>

      <Card>
        <div className="p-4">
          <h4 className="font-semibold mb-2">Calorie Intake</h4>
          <Slider
            value={[targets.calorieIntake]}
            onValueChange={(value) => handleTargetChange('calorieIntake', value)}
            max={4000}
            step={50}
          />
          <p>Current target: {targets.calorieIntake} calories (Suggested: {suggestedTargets.calorieIntake} calories)</p>
        </div>
      </Card>

      <Card>
        <div className="p-4">
          <h4 className="font-semibold mb-2">Hobby/Leisure Time (hours)</h4>
          <Slider
            value={[targets.hobbyTime]}
            onValueChange={(value) => handleTargetChange('hobbyTime', value)}
            max={8}
            step={0.5}
          />
          <p>Current target: {targets.hobbyTime} hours (Suggested: {suggestedTargets.hobbyTime} hours)</p>
        </div>
      </Card>
    </div>
  );
};

export default Targets;
