import React from 'react';

export const Tabs = ({ value, onValueChange, children, className = '', ...props }) => (
  <div className={`${className}`} {...props}>
    {React.Children.map(children, child =>
      React.cloneElement(child, { value, onValueChange })
    )}
  </div>
);

export const TabsList = ({ children, className = '', ...props }) => (
  <div className={`inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground ${className}`} {...props}>
    {children}
  </div>
);

export const TabsTrigger = ({ value, children, className = '', ...props }) => (
  <button
    className={`inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm ${className}`}
    onClick={() => props.onValueChange(value)}
    data-state={props.value === value ? 'active' : 'inactive'}
    {...props}
  >
    {children}
  </button>
);

export const TabsContent = ({ value, children, className = '', ...props }) => (
  <div
    className={`mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ${className}`}
    hidden={props.value !== value}
    {...props}
  >
    {children}
  </div>
);
